import React from "react"
import footerStyles from "../styles/footer.module.scss"

const Footer = () => {
  return (
    <footer className={footerStyles.siteFooter}>
      <div className={footerStyles.container}>
        <p>Site last updated by Saad on November 12, 2020 at 12:31pm CST. </p>
      </div>
    </footer>
  )
}

export default Footer
