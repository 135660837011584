import React from "react"
import { Link } from "gatsby"
import headerStyles from "../styles/header.module.scss"
import me from "../images/me.jpg"

const Header = () => {
  return (
    <header className={headerStyles.header}>
      <div className={headerStyles.heroContent}>
        <Link to="/" className={headerStyles.header__avatar}>
          <img
            className={headerStyles.header__avatar_img}
            alt="Saad Zniber"
            src={me}
          />
        </Link>
        <p>
          <Link to="/">Saad Zniber</Link>
        </p>
        <p className={headerStyles.description}>
          {" "}
          Maker, learner, I'm always building something.
        </p>
      </div>
      <nav className={headerStyles.navContainer}>
        <ul className={headerStyles.navList}>
          <li>
            <Link activeClassName={headerStyles.activeMenuItem} to="/">
              Home
            </Link>
          </li>
          {/*       <li>
            <Link activeClassName={headerStyles.activeMenuItem} to="/blog">
              Blog
            </Link>
          </li>
          <li>
            <Link activeClassName={headerStyles.activeMenuItem} to="/contact">
              Contact
            </Link>
          </li>
          <li>
            <Link activeClassName={headerStyles.activeMenuItem} to="/about">
              About
            </Link>
            </li>*/}
        </ul>
      </nav>
    </header>
  )
}

export default Header
